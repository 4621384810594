.fade-enter {
    opacity: 0.75;
    z-index: 1;
    transform: scale(2);
  }
  
  .fade-enter.fade-enter-active {
    opacity: 1;
    transition: all 450ms ease;
    transform: scale(1);
  }
  .App {
      overflow-x: hidden;
  }