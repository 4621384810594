@import url(IRANSans/WebFonts/css/fontiran.css);
* {
  box-sizing: border-box;
}
body {
  margin: 0;
}
a {
  text-decoration: none;
}
::-webkit-scrollbar {
  width: 10px;
  padding: 0.25rem;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(124, 124, 124, 0.5);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(124, 124, 124, 0.75);

}