.LazyLoadingFallback {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
.Loading {
    width: 6rem;
    height: 6rem;
}

.Text {
    color: rgba(0, 0, 0, 0.5);
    font-weight: 400;
    font-size: 1.25rem;
    margin-top: 2rem;
    font-family: IranSans , 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}